import { getHeaders } from "utils";
import {
  CLEAR_CARD_DETAILS,
  GET_ADDRESS_FAIL,
  GET_ADDRESS_REQUEST,
  GET_ADDRESS_SUCCESS,
  GET_CARD_FAIL,
  GET_CARD_REQUEST,
  GET_CARD_SUCCESS,
  GET_REQUESTHISTORY_FAIL,
  GET_REQUESTHISTORY_REQUEST,
  GET_REQUESTHISTORY_SUCCESS,
  GET_SELLER_ADDRESS_FAIL,
  GET_SELLER_ADDRESS_REQUEST,
  GET_SELLER_ADDRESS_SUCCESS,
  GET_VERSION_FAIL,
  GET_VERSION_REQUEST,
  GET_VERSION_SUCCESS,
  HOME_PAGE,
  POST_ACTIONREQUEST_FAIL,
  POST_ACTIONREQUEST_REQUEST,
  POST_ACTIONREQUEST_SUCCESS,
  POST_ADDRESS_FAIL,
  POST_ADDRESS_REQUEST,
  POST_ADDRESS_SUCCESS,
  POST_LOGIN_FAIL,
  POST_LOGIN_REQUEST,
  POST_LOGIN_SUCCESS,
} from "../constants/Home";
import { apiFetch } from "apiClient";

const API_DOMAIN = process.env.REACT_APP_API_DOMAIN;

export const saveData = (data) => {
  return (dispatch) => {
    dispatch({
      type: HOME_PAGE,
      payload: data,
    });
  };
};

export const login = (data, handleSuccess, handleFail) =>
  function (dispatch) {
    dispatch({
      type: POST_LOGIN_REQUEST,
    });
    return apiFetch(`${API_DOMAIN}api/login/`, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json().then((body) => ({ response, body })))
      .then(({ response, body }) => {
        if (!body.success) {
          dispatch({
            type: POST_LOGIN_FAIL,
            payload: body.error,
          });

          handleFail(body);
        } else {
          // localStorage.setItem('zb_user', JSON.stringify(body));
          dispatch({
            type: POST_LOGIN_SUCCESS,
            payload: body,
          });
          if (body.data.session_token) {
            localStorage.setItem("accessToken", body.data.session_token);
            localStorage.setItem("userDetail", JSON.stringify(body.data));
          }
          handleSuccess(body);
        }
      })
      .catch((error) => {
        console.log(error, "Error.");
        handleFail(error);
      });
  };

export const userAuth = (data, handleSuccess, handleFail) =>
  function (dispatch) {
    dispatch({
      type: POST_LOGIN_REQUEST,
    });
    return apiFetch(`${API_DOMAIN}api/login_authentication/`, {
      method: "POST",
      body: JSON.stringify(data),
      // credentials: 'include',
      headers: {
        "Content-Type": "application/json",
        // 'withCredentials': 'true'
      },
    })
      .then((response) => response.json().then((body) => ({ response, body })))
      .then(({ response, body }) => {
        if (!body.success) {
          dispatch({
            type: POST_LOGIN_FAIL,
            payload: body.error,
          });

          handleFail(body);
        } else {
          // localStorage.setItem('zb_user', JSON.stringify(body));
          dispatch({
            type: POST_LOGIN_SUCCESS,
            payload: body,
          });
          if (body.data.session_token) {
            localStorage.setItem("accessToken", body.data.session_token);
            localStorage.setItem("userDetail", JSON.stringify(body.data));
            localStorage.removeItem("userEmail");

            // document.cookie = `session_token=${body.data.session_token}`;
          }
          handleSuccess(body);
        }
      })
      .catch((error) => {
        console.log(error, "Error.");
      });
  };

export const addAddress = (data, handleSuccess, handleFail) =>
  function (dispatch) {
    dispatch({
      type: POST_ADDRESS_REQUEST,
    });
    return apiFetch(`${API_DOMAIN}api/address/`, {
      method: "POST",
      body: JSON.stringify(data),
      headers: getHeaders(),
    })
      .then((response) => response.json().then((body) => ({ response, body })))
      .then(({ response, body }) => {
        if (!body.success) {
          dispatch({
            type: POST_ADDRESS_FAIL,
            payload: body.error,
          });

          handleFail(body);
        } else {
          dispatch({
            type: POST_ADDRESS_SUCCESS,
            payload: body,
          });

          handleSuccess(body);
        }
      });
  };

export const saveVersion = (data, handleSuccess, handleFail) =>
  function (dispatch) {
    dispatch({
      type: POST_ADDRESS_REQUEST,
    });
    return apiFetch(`${API_DOMAIN}api/app_version/`, {
      method: "POST",
      body: JSON.stringify(data),
      headers: getHeaders(),
    })
      .then((response) => response.json().then((body) => ({ response, body })))
      .then(({ response, body }) => {
        if (!body.success) {
          dispatch({
            type: POST_ADDRESS_FAIL,
            payload: body.error,
          });

          handleFail(body);
        } else {
          dispatch({
            type: POST_ADDRESS_SUCCESS,
            payload: body,
          });

          handleSuccess(body);
        }
      });
  };

export const editVersion = (data, handleSuccess, handleFail) =>
  function (dispatch) {
    dispatch({
      type: POST_ADDRESS_REQUEST,
    });
    return apiFetch(`${API_DOMAIN}api/app_version/`, {
      method: "PUT",
      body: JSON.stringify(data),
      headers: getHeaders(),
    })
      .then((response) => response.json().then((body) => ({ response, body })))
      .then(({ response, body }) => {
        if (!body.success) {
          dispatch({
            type: POST_ADDRESS_FAIL,
            payload: body.error,
          });

          handleFail(body);
        } else {
          dispatch({
            type: POST_ADDRESS_SUCCESS,
            payload: body,
          });

          handleSuccess(body);
        }
      });
  };

export const registerUser = (data, handleSuccess, handleFail) =>
  function (dispatch) {
    dispatch({
      type: POST_LOGIN_REQUEST,
    });
    return apiFetch(`${API_DOMAIN}api/pclive_users/`, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        // 'Authorization': 'Bearer ' + getToken()
      },
    })
      .then((response) => response.json().then((body) => ({ response, body })))
      .then(({ response, body }) => {
        if (!body.success) {
          dispatch({
            type: POST_LOGIN_FAIL,
            payload: body.error,
          });

          handleFail(body);
        } else {
          dispatch({
            type: POST_LOGIN_SUCCESS,
            payload: body,
          });

          handleSuccess(body);
        }
      });
  };

export const resendOtp = (data, handleSuccess, handleFail) =>
  function (dispatch) {
    dispatch({
      type: POST_ADDRESS_REQUEST,
    });
    return apiFetch(`${API_DOMAIN}api/send_otp/`, {
      method: "POST",
      body: JSON.stringify(data),
      headers: getHeaders(),
    })
      .then((response) => response.json().then((body) => ({ response, body })))
      .then(({ response, body }) => {
        if (!body.success) {
          dispatch({
            type: POST_ADDRESS_FAIL,
            payload: body.error,
          });

          handleFail(body);
        } else {
          dispatch({
            type: POST_ADDRESS_SUCCESS,
            payload: body,
          });

          handleSuccess(body);
        }
      });
  };
export const getVersion = (
  currentPage,
  sortData,
  query,
  forceUpdate,
  iosEnable,
  androidEnable
) => {
  return function (dispatch, getState) {
    dispatch({
      type: GET_VERSION_REQUEST,
    });
    return apiFetch(
      `${API_DOMAIN}api/app_version?paginate=true&limit=10&page=${currentPage}${
        sortData && sortData.length
          ? `&order_by=${JSON.stringify(sortData)}`
          : ""
      }${forceUpdate ? `&is_force_update=${forceUpdate}` : ""}${
        iosEnable ? `&ios_update=${iosEnable}` : ""
      }${androidEnable ? `&android_update=${androidEnable}` : ""}${
        query ? `&search_text=${encodeURIComponent(query)}` : ""
      }`,
      {
        method: "GET",
        credentials: "include",
        headers: getHeaders(),
      }
    )
      .then((response) => response.json().then((body) => ({ response, body })))
      .then(({ response, body }) => {
        if (!response.ok) {
          dispatch({
            type: GET_VERSION_FAIL,
            payload: body.error,
          });
          // handleFailure(body);
        } else {
          dispatch({
            type: GET_VERSION_SUCCESS,
            payload: body,
          });
          // handleSuccess(body);
        }
      })
      .catch((error) => {
        console.log(error, "error");
      });
  };
};

export const getAddress = (allAddress, sortData, query) => {
  return function (dispatch, getState) {
    dispatch({
      type: GET_ADDRESS_REQUEST,
    });
    return apiFetch(
      `${API_DOMAIN}api/address?${
        sortData && sortData.length
          ? `order_by=${JSON.stringify(sortData)}`
          : ""
      }${query ? `&search_text=${encodeURIComponent(query)}` : ""}${
        allAddress ? `&all_address=${encodeURIComponent(allAddress)}` : ""
      }`,
      {
        method: "GET",
        credentials: "include",
        headers: getHeaders(),
      }
    )
      .then((response) => response.json().then((body) => ({ response, body })))
      .then(({ response, body }) => {
        if (!response.ok) {
          dispatch({
            type: GET_ADDRESS_FAIL,
            payload: body.error,
          });
          // handleFailure(body);
        } else {
          dispatch({
            type: GET_ADDRESS_SUCCESS,
            payload: body,
          });
          // handleSuccess(body);
        }
      })
      .catch((error) => {
        console.log(error, "error");
      });
  };
};

export const getSellerAddress = (userId) => {
  return function (dispatch, getState) {
    dispatch({
      type: GET_SELLER_ADDRESS_REQUEST,
    });
    return apiFetch(
      `${API_DOMAIN}api/address${userId ? `?user_id=${userId}` : ""}`,
      {
        method: "GET",
        credentials: "include",
        headers: getHeaders(),
      }
    )
      .then((response) => response.json().then((body) => ({ response, body })))
      .then(({ response, body }) => {
        if (!response.ok) {
          dispatch({
            type: GET_SELLER_ADDRESS_FAIL,
            payload: body.error,
          });
        } else {
          dispatch({
            type: GET_SELLER_ADDRESS_SUCCESS,
            payload: body,
          });
        }
      })
      .catch((error) => {
        console.log(error, "error");
      });
  };
};

export const editAddress = (data, handleSuccess, handleFail) =>
  function (dispatch) {
    dispatch({
      type: POST_ADDRESS_REQUEST,
    });
    return apiFetch(`${API_DOMAIN}api/address/`, {
      method: "PUT",
      body: JSON.stringify(data),
      headers: getHeaders(),
    })
      .then((response) => response.json().then((body) => ({ response, body })))
      .then(({ response, body }) => {
        console.log(body, "bodybody");
        if (!body.success) {
          dispatch({
            type: POST_ADDRESS_FAIL,
            payload: body.error,
          });

          handleFail(body);
        } else {
          dispatch({
            type: POST_ADDRESS_SUCCESS,
            payload: body,
          });

          handleSuccess(body);
        }
      });
  };

export const addressActivation = (data, handleSuccess, handleFail) =>
  function (dispatch) {
    dispatch({
      type: POST_ADDRESS_REQUEST,
    });
    return apiFetch(`${API_DOMAIN}api/address/`, {
      method: "PATCH",
      body: JSON.stringify(data),
      headers: getHeaders(),
    })
      .then((response) => response.json().then((body) => ({ response, body })))
      .then(({ response, body }) => {
        console.log(body, "bodybody");
        if (!body.success) {
          dispatch({
            type: POST_ADDRESS_FAIL,
            payload: body.error,
          });

          handleFail(body);
        } else {
          dispatch({
            type: POST_ADDRESS_SUCCESS,
            payload: body,
          });

          handleSuccess(body);
        }
      });
  };

export const deleteAddress = (id, handleSuccess, handleFail) => {
  return function (dispatch) {
    dispatch({
      type: POST_ADDRESS_REQUEST,
    });

    return apiFetch(`${API_DOMAIN}api/address?address_id=${id}`, {
      method: "DELETE",
      headers: getHeaders(),
    })
      .then((response) => response.json().then((body) => ({ response, body })))
      .then(({ response, body }) => {
        if (!body.success) {
          dispatch({
            type: POST_ADDRESS_FAIL,
            payload: "",
          });
          handleFail(body);
        } else {
          dispatch({
            type: POST_ADDRESS_SUCCESS,
            payload: "",
          });
          handleSuccess(body.body);
        }
      });
  };
};

export const clearCardDetails = () =>
  function (dispatch) {
    dispatch({ type: CLEAR_CARD_DETAILS });
  };

export const requestAction = (data, handleSuccess, handleFail) =>
  function (dispatch) {
    dispatch({
      type: POST_ACTIONREQUEST_REQUEST,
    });
    return apiFetch(`${API_DOMAIN}api/request_action/`, {
      method: "POST",
      body: JSON.stringify(data),
      headers: getHeaders(),
    })
      .then((response) => response.json().then((body) => ({ response, body })))
      .then(({ response, body }) => {
        if (!body.success) {
          dispatch({
            type: POST_ACTIONREQUEST_FAIL,
            payload: body.error,
          });

          handleFail(body);
        } else {
          dispatch({
            type: POST_ACTIONREQUEST_SUCCESS,
            payload: body,
          });

          handleSuccess(body);
        }
      });
  };

export const getRequestHistory = (data, handleSuccess, handleFail) =>
  function (dispatch) {
    dispatch({
      type: GET_REQUESTHISTORY_REQUEST,
    });
    return apiFetch(`${API_DOMAIN}api/request_history/`, {
      method: "POST",
      body: JSON.stringify(data),
      headers: getHeaders(),
    })
      .then((response) => response.json().then((body) => ({ response, body })))
      .then(({ response, body }) => {
        console.log("history", data);
        if (!body.success) {
          dispatch({
            type: GET_REQUESTHISTORY_FAIL,
            payload: body.error,
          });

          handleFail(body);
        } else {
          dispatch({
            type: GET_REQUESTHISTORY_SUCCESS,
            payload: body,
          });

          handleSuccess(body);
        }
      });
  };

export const getCardDetails = (id) => {
  return function (dispatch, getState) {
    dispatch({
      type: GET_CARD_REQUEST,
    });
    return apiFetch(`${API_DOMAIN}api/card?card_id=${id}`, {
      method: "GET",
      credentials: "include",
      headers: getHeaders(),
    })
      .then((response) => response.json().then((body) => ({ response, body })))
      .then(({ response, body }) => {
        if (!response.ok) {
          dispatch({
            type: GET_CARD_FAIL,
            payload: body.error,
          });
          // handleFailure(body);
        } else {
          dispatch({
            type: GET_CARD_SUCCESS,
            payload: body,
          });
          // handleSuccess(body);
        }
      })
      .catch((error) => {
        console.log(error, "error");
      });
  };
};
