import { getHeaders } from "utils";
import {
  GET_ALL_DASHBOARD_DATA_FAIL,
  GET_ALL_DASHBOARD_DATA_REQUEST,
  GET_ALL_DASHBOARD_DATA_SUCCESS,
  GET_SOLD_AND_ORDER_FAIL,
  GET_SOLD_AND_ORDER_REQUEST,
  GET_SOLD_AND_ORDER_SUCCESS,
  GET_ORDER_AND_EARNING_FAIL,
  GET_ORDER_AND_EARNING_REQUEST,
  GET_ORDER_AND_EARNING_SUCCESS,
  GET_EARNING_FAIL,
  GET_EARNING_REQUEST,
  GET_EARNING_SUCCESS,
  LOGOUT_STATE,
} from "../constants/Dashboard";
import { apiFetch } from "apiClient";

const API_DOMAIN = process.env.REACT_APP_API_DOMAIN;

// const getToken = () =>{
//     const token =   localStorage.getItem("accessToken")
//     return token

// }
// let _csrfToken = null;

// async function getCsrfToken() {
//   if (_csrfToken === null) {
//     const response = await fetch(`${API_HOST}/csrf/`, {
//       credentials: 'include',
//     });
//     const data = await response.json();
//     _csrfToken = data.csrfToken;
//   }
//   return _csrfToken;
// }

export const logOutAction = () => {
  return (dispatch) => {
    dispatch({
      type: LOGOUT_STATE,
      payload: "",
    });
  };
};
export const getAllDashboardDetail = (startDate, endDate) => {
  return function (dispatch, getState) {
    dispatch({
      type: GET_ALL_DASHBOARD_DATA_REQUEST,
    });
    return apiFetch(
      `${API_DOMAIN}api/dashboard/dashboard_data?start_date=${startDate}&end_date=${endDate}`,
      {
        method: "GET",
        credentials: "include",
        headers: getHeaders(),
      }
    )
      .then((response) => response.json().then((body) => ({ response, body })))
      .then(({ response, body }) => {
        if (!response.ok) {
          dispatch({
            type: GET_ALL_DASHBOARD_DATA_FAIL,
            payload: body.error,
          });
          // handleFailure(body);
        } else {
          dispatch({
            type: GET_ALL_DASHBOARD_DATA_SUCCESS,
            payload: body.data,
          });
          // handleSuccess(body);
        }
      })
      .catch((error) => {
        console.log(error, "error");
      });
  };
};

export const getSoldAndOrderDetails = (startDate, endDate) => {
  return function (dispatch, getState) {
    dispatch({
      type: GET_SOLD_AND_ORDER_REQUEST,
    });
    return apiFetch(
      `${API_DOMAIN}api/dashboard/statistics?start_date=${startDate}&end_date=${endDate}`,
      {
        method: "GET",
        credentials: "include",
        headers: getHeaders(),
      }
    )
      .then((response) => response.json().then((body) => ({ response, body })))
      .then(({ response, body }) => {
        if (!response.ok) {
          dispatch({
            type: GET_SOLD_AND_ORDER_FAIL,
            payload: body.error,
          });
          // handleFailure(body);
        } else {
          dispatch({
            type: GET_SOLD_AND_ORDER_SUCCESS,
            payload: body.data,
          });
          // handleSuccess(body);
        }
      })
      .catch((error) => {
        console.log(error, "error");
      });
  };
};

export const getOrdersAndEarnings = (startDate, endDate) => {
  return function (dispatch, getState) {
    dispatch({
      type: GET_ORDER_AND_EARNING_REQUEST,
    });
    return apiFetch(
      `${API_DOMAIN}api/dashboard/order_data?start_date=${startDate}&end_date=${endDate}`,
      {
        method: "GET",
        credentials: "include",
        headers: getHeaders(),
      }
    )
      .then((response) => response.json().then((body) => ({ response, body })))
      .then(({ response, body }) => {
        if (!response.ok) {
          dispatch({
            type: GET_ORDER_AND_EARNING_FAIL,
            payload: body.error,
          });
          // handleFailure(body);
        } else {
          dispatch({
            type: GET_ORDER_AND_EARNING_SUCCESS,
            payload: body.data,
          });
          // handleSuccess(body);
        }
      })
      .catch((error) => {
        console.log(error, "error");
      });
  };
};

export const getEarningReports = (startDate, endDate) => {
  return function (dispatch, getState) {
    dispatch({
      type: GET_EARNING_REQUEST,
    });
    return apiFetch(
      `${API_DOMAIN}api/dashboard/earning_data?start_date=${startDate}&end_date=${endDate}`,
      {
        method: "GET",
        credentials: "include",
        headers: getHeaders(),
      }
    )
      .then((response) => response.json().then((body) => ({ response, body })))
      .then(({ response, body }) => {
        if (!response.ok) {
          dispatch({
            type: GET_EARNING_FAIL,
            payload: body.error,
          });
          // handleFailure(body);
        } else {
          dispatch({
            type: GET_EARNING_SUCCESS,
            payload: body.data,
          });
          // handleSuccess(body);
        }
      })
      .catch((error) => {
        console.log(error, "error");
      });
  };
};
