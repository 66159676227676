export const isTokenExpired = () => {
  const userDetails = localStorage.getItem("userDetail");
  const expiryTime = new Date(
    JSON.parse(userDetails)?.["token_expiry "].split(".")[0] + "Z"
  );
  const currentTime = new Date();

  return currentTime > expiryTime;
};

function handleLogout() {
  localStorage.clear();

  // Redirect to login
  window.location.href = "/auth/login";
}

export async function apiFetch(url, options = {}) {
  if (isTokenExpired()) handleLogout();
  else {
    const response = await fetch(url, options);

    if (
      response?.status === 410 ||
      response?.status === 440 ||
      response?.message?.toLowerCase() === "session expired"
    ) {
      // Token has expired or is invalid, log out the user
      handleLogout();
      return Promise.reject("Session expired");
    }

    // Handle other non-200 status codes
    if (!response.ok) {
      return Promise.reject("Error: " + response.statusText);
    }

    // Return the JSON response
    return response;
  }
}
